<template>
  <v-toolbar flat style="background-color: #fafafa">
    <v-toolbar-title>
      <h2>{{ title }}</h2>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <app-timeselector @datesUpdate="datesUpdate" />
    <pre></pre>
    <app-export-button
      :source="source"
      :dates="dates"
      :filters="filters"
      :customer-id="customerId"
      style="margin-left: 5px"
    />
  </v-toolbar>
</template>

<script>
  import Timeselector from '@/components/common/Timeselector.vue'
  import ExportButton from '@/components/reporting/DownloadData.vue'
  export default {
    components: {
      AppTimeselector: Timeselector,
      AppExportButton: ExportButton
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['title', 'filters', 'source', 'customerId'],
    data() {
      return {
        dates: []
      }
    },
    methods: {
      datesUpdate(dates) {
        this.dates = dates
        this.$emit('datesUpdate', dates)
      }
    }
  }
</script>
