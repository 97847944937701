<template>
  <v-card>
    <v-container fluid style="margin-top: 10px">
      <app-overlay :loading="loading" />
      <v-row>
        <v-col
          v-for="type in Object.keys(seriesTypes)"
          :key="type"
          cols="12"
          md="4"
        >
          <app-chart
            :type="type"
            :data="timeSeries[type]"
            :total="catTotals[type]"
            :parameters="seriesTypes[type]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import moment from 'moment'
  import Chart from './Chart.vue'
  import Overlay from '@/components/common/Overlay.vue'
  export default {
    components: {
      AppChart: Chart,
      AppOverlay: Overlay
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['reportingData', 'loading'],
    data() {
      return {
        rawCats: [
          'sessions',
          'bounces',
          'sessionDuration',
          'pageViews',
          'impressions',
          'revenue'
        ],
        seriesTypes: {
          sessions: {
            formatter: 'impressions',
            type: 'raw'
          },
          pageViews: {
            formatter: 'impressions',
            type: 'raw'
          },
          bounceRate: {
            formatter: 'percentage',
            type: 'ratio',
            variables: ['bounces', 'sessions'],
            multiplier: 1
          },
          averageSessionDuration: {
            formatter: 'impressions',
            type: 'ratio',
            variables: ['sessionDuration', 'sessions'],
            multiplier: 1
          },
          pageViewsPerSessions: {
            formatter: 'impressions',
            type: 'ratio',
            variables: ['pageViews', 'sessions'],
            multiplier: 1
          },
          revenue: {
            formatter: 'revenue',
            type: 'raw'
          },
          cpm: {
            formatter: 'cpm',
            type: 'ratio',
            variables: ['revenue', 'impressions'],
            multiplier: 1000
          },
          rpmPerPageView: {
            formatter: 'cpm',
            type: 'ratio',
            variables: ['revenue', 'pageViews'],
            multiplier: 1000
          },
          rpmPerSession: {
            formatter: 'cpm',
            type: 'ratio',
            variables: ['revenue', 'sessions'],
            multiplier: 1000
          }
        }
      }
    },

    computed: {
      timeSeries() {
        const series = {}
        Object.keys(this.seriesTypes).forEach(
          (cat) => (series[cat] = [{ name: cat, data: [] }])
        )
        this.rawCats.forEach((cat) => (series[cat] = [{ name: cat, data: [] }]))
        if (this.reportingData !== undefined) {
          this.reportingData.forEach((item) => {
            const unixDate = parseInt(
              moment.utc(item.day, 'YYYYMMDD').format('x')
            )
            Object.keys(this.seriesTypes).forEach((cat) => {
              if (this.seriesTypes[cat].type == 'raw') {
                series[cat][0].data.push([unixDate, parseFloat(item[cat])])
              }
              if (this.seriesTypes[cat].type == 'ratio') {
                series[cat][0].data.push([
                  unixDate,
                  (item[this.seriesTypes[cat].variables[0]] /
                    item[this.seriesTypes[cat].variables[1]]) *
                    this.seriesTypes[cat].multiplier
                ])
              }
            })
          })
        }
        return series
      },
      catTotals() {
        let total = {}
        Object.keys(this.seriesTypes).forEach((cat) => {
          if (this.seriesTypes[cat].type == 'raw') {
            total[cat] = 0
          } else {
            total[cat] = [0, 0]
          }
        })
        if (this.reportingData !== undefined) {
          this.reportingData.forEach((item) => {
            Object.keys(this.seriesTypes).forEach((cat) => {
              if (this.seriesTypes[cat].type == 'raw') {
                total[cat] += parseFloat(item[cat])
              }
              if (this.seriesTypes[cat].type == 'ratio') {
                total[cat][0] += parseFloat(
                  item[this.seriesTypes[cat].variables[0]]
                )
                total[cat][1] += parseFloat(
                  item[this.seriesTypes[cat].variables[1]]
                )
              }
            })
          })
        }
        Object.keys(this.seriesTypes).forEach((cat) => {
          if (this.seriesTypes[cat].type == 'ratio') {
            total[cat] =
              (total[cat][0] / total[cat][1]) * this.seriesTypes[cat].multiplier
          }
        })
        return total
      }
    },
    created() {
      //this.rawCats.forEach(cat => this.catTotals[cat] = 0);
    },
    methods: {
      /*
			setCatTotals(){
				this.rawCats.forEach((type) => {
					this.catTotals[type] = 0
					console.log(this.timeSeries)
					/*
					this.timeSeries[type].forEach((item) => {
						item.data.forEach((elem) => {
								
								this.catTotals[type] += elem[1]
						})
					})
					
				})
				//this.catTotals['cpm'] = this.catTotals['revenue'] / this.catTotals['impressions'] * 1000
			},
			*/
    }
  }
</script>
