<template>
  <v-container fluid>
    <div class="base-title" style="margin-bottom: 10px">
      <h2>Mapping</h2>
    </div>

    <v-tabs v-model="selectedPartnerId" background-color="black" dark>
      <v-tab v-for="p in partners" :key="p.name" @click="selectPartner(p.name)">
        {{ p.displayName }}
      </v-tab>
    </v-tabs>

    <v-tabs v-model="selectedCategoryId">
      <v-tab
        v-for="c in selectedPartner.categories"
        :key="c"
        @click="selectCategory(c)"
      >
        {{ c }}
      </v-tab>
    </v-tabs>

    <app-mapping
      :mapping-specs="mappingSpecs"
      :selected-partner="partner"
      :selected-category="category"
    />
  </v-container>
</template>

<script>
  import Mapping from './Mapping.vue'
  export default {
    components: {
      AppMapping: Mapping
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['mappingType'],
    data() {
      return {
        partners: [
          {
            name: 'actirise',
            displayName: 'Actirise',
            categories: ['site']
          },
          {
            name: 'missena',
            displayName: 'Missena',
            categories: ['site']
          },
          {
            name: 'taboola',
            displayName: 'Taboola',
            categories: ['site', 'device']
          },
          {
            name: 'googleAdManager',
            displayName: 'Google Ad Manager',
            categories: ['site', 'device', 'order']
          },
          { name: 'sublime', displayName: 'Sublime', categories: ['site'] },
          {
            name: 'teads',
            displayName: 'Teads',
            categories: ['site', 'device']
          },
          {
            name: 'bliink',
            displayName: 'Bliink',
            categories: ['site', 'device']
          },
          {
            name: 'avantis',
            displayName: 'avantis',
            categories: ['site']
          },
          { name: 'impactify', displayName: 'impactify', categories: ['site'] },
          { name: 'seedtag', displayName: 'seedtag', categories: ['site'] },
          {
            name: 'googleAnalytics',
            displayName: 'Google Analytics',
            categories: ['device']
          }
        ],
        selectedPartnerId: 0,
        selectedCategoryId: 0
      }
    },
    computed: {
      selectedPartner() {
        const foo = this.partners.findIndex((x) => x.name == this.partner)
        return foo == -1 ? this.partners[0] : this.partners[foo]
      },
      partner() {
        if (this.$route.query.partner == undefined) {
          return this.partners[0].name
        }
        let foo = this.partners.findIndex(
          (x) => x.name == this.$route.query.partner
        )
        foo = foo == -1 ? 0 : foo
        return this.partners[foo].name
      },
      category() {
        if (this.$route.query.category == undefined) {
          return this.selectedPartner.categories[0]
        }
        let foo = this.selectedPartner.categories.findIndex(
          (x) => x == this.$route.query.category
        )
        foo = foo == -1 ? 0 : foo
        return this.selectedPartner.categories[foo]
      },
      mappingSpecs() {
        let partner = this.partner
        let category = this.category
        return {
          apiEndPoint: 'mapping/' + partner + '/' + category,
          type: {
            partner,
            category
          }
        }
      }
    },
    watch: {
      partner() {
        this.updateSelectedPartnerId()
        this.updateSelectedCategoryId()
      }
    },
    created() {
      this.updateSelectedPartnerId()
      this.updateSelectedCategoryId()
    },
    methods: {
      selectPartner(partner) {
        this.$router.push(
          '/mapping?partner=' + partner + '&category=' + this.category
        )
      },
      selectCategory(category) {
        this.$router.push(
          '/mapping?partner=' + this.partner + '&category=' + category
        )
      },
      updateSelectedPartnerId() {
        this.selectedPartnerId = this.partners.findIndex(
          (x) => x.name == this.$route.query.partner
        )
      },
      updateSelectedCategoryId() {
        this.selectedCategoryId = this.selectedPartner.categories.findIndex(
          (x) => x == this.$route.query.category
        )
      }
    }
  }
</script>
