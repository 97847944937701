<template>
  <v-icon @click="downloadData">mdi-cloud-download</v-icon>
</template>
<script>
  import axios from 'axios'
  import moment from 'moment'
  export default {
    props: {
      source: {
        type: String,
        default: ''
      },
      filters: {
        type: Object,
        default: () => {}
      },
      dates: {
        type: Array,
        default: () => []
      },
      customerId: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        loading: false
      }
    },
    methods: {
      async downloadData() {
        this.loading = true
        const request = {
          type: this.source,
          action: 'exportData',
          data: {
            filters: this.filters,
            dates: this.dates,
            customerId: this.customerId
          }
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post(
            '/download/',
            JSON.stringify(request),
            { headers: { Authorization: `Bearer ${accessToken}` } }
          )
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/csv'
            })
          )
          const link = document.createElement('a')
          link.href = url
          const filename =
            'export-' + moment().format('YYYY-MM-DD, HH:mm:ss') + '.csv'
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      }
    }
  }
</script>
