<template>
  <v-btn
    rounded
    small
    color="red"
    style="color: white; margin-left: 10px"
    :loading="loading"
    @click="deleteUser(item.id)"
  >
    Delete
  </v-btn>
</template>

<script>
  import axios from 'axios'
  export default {
    props: ['item'],
    data: () => ({
      loading: false
    }),
    methods: {
      async deleteUser(id) {
        if (
          id &&
          confirm('Êtes vous sûr de vouloir supprimer cet utilisateur ?')
        ) {
          const request = {
            type: 'users/users',
            action: 'delete',
            data: id
          }
          this.loading = true
          try {
            const accessToken = await this.$auth.getAccessToken()
            const response = await axios.post('/', JSON.stringify(request), {
              headers: { Authorization: `Bearer ${accessToken}` }
            })
            if (response && response.data) {
              this.$emit('deleteItem', id)
              this.loading = false
            } else {
              this.$emit(
                'alert',
                "Erreur lors de la suppression de l'utilisateur!"
              )
              console.error(response)
              this.loading = false
            }
          } catch (e) {
            console.error(e)
            this.loading = false
          }
        }
      }
    }
  }
</script>
