<template>
  <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="black" dark>
    <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
      <!--<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>-->
      <v-app-bar-nav-icon @click.stop="updateDrawer"></v-app-bar-nav-icon>
    </v-toolbar-title>
    <v-img
      class="hidden-sm-and-down"
      src="logo2019_site.png"
      max-height="50px"
      contain
    />
    <!--
    <v-text-field
      flat
      solo-inverted
      hide-details
      prepend-inner-icon="search"
      label="Search"
      class="hidden-sm-and-down"
    ></v-text-field>
  -->
    <v-spacer></v-spacer>

    <span v-if="authenticated" class="">
      {{ name }}
    </span>
    <v-btn v-if="authenticated" icon>
      <v-icon @click="logout">mdi-logout</v-icon>
    </v-btn>
    <!--

    <v-btn icon>
      <v-icon>apps</v-icon>
    </v-btn>
    <v-btn icon>
      <v-icon>notifications</v-icon>
    </v-btn>
    <v-btn
      icon
      large
    >
      <v-avatar
        size="32px"
        item
      >
        <v-img
          src="https://cdn.vuetifyjs.com/images/logos/logo.svg"
          alt="Vuetify"
        >
        </v-img></v-avatar>
    </v-btn>
  -->
  </v-app-bar>
</template>

<script>
  //import axios from 'axios'
  export default {
    data: function () {
      return {
        drawer: null,
        authenticated: false,
        name: ''
      }
    },
    watch: {
      // Everytime the route changes, check for auth status
      $route: 'isAuthenticated'
    },
    created() {
      //this.isAuthenticated()
    },
    methods: {
      isAuthenticated() {
        this.$auth.isAuthenticated().then((res) => {
          this.authenticated = res
          if (this.name == '' && res) {
            this.$auth.getUser().then((res) => {
              console.log(res)
              if (res) this.name = res.name
            })
          }
        })
      },
      updateDrawer() {
        this.$emit('drawerUpdate')
      },

      logout() {
        this.$auth.logout()
        this.$router.push('/')
        this.name = ''
      }
    }
  }
</script>
