<template>
  <v-container fluid>
    <div class="base-title" style="margin-bottom: 10px">
      <h2>Upload</h2>
    </div>
    <v-card>
      <v-card-text>
        <app-partner @selectedPartner="selectedPartner = $event" />
        <v-file-input
          v-if="selectedPartner !== 0"
          v-model="fileUpload"
          :rules="rules"
          accept=".csv"
          placeholder="Pick an file"
        ></v-file-input>
        <v-alert v-for="message in dangerMessages" :key="message" type="error">
          {{ message }}
        </v-alert>
        <v-alert v-if="okayMessages" type="success"> Upload performed </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
          v-if="fileUpload !== null"
          rounded
          color="success"
          :loading="submitLoading"
          :disabled="submitLoading"
          style="margin: 10px"
          @click="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import Partner from './partner/index.vue'

  export default {
    components: {
      appPartner: Partner
    },
    data: () => ({
      selectedPartner: 0,
      fileUpload: null,
      submitLoading: false,
      dangerMessages: [],
      okayMessages: false,
      rules: [
        (value) => {
          if (!value || value.type != 'text/csv') {
            return 'File needs to be a csv'
          } else {
            return true
          }
        }
      ]
    }),
    methods: {
      submit() {
        const formData = new FormData()
        formData.append('file', this.fileUpload)
        formData.append('partnerId', this.selectedPartner)
        this.uploadData(formData)
      },
      async uploadData(formData) {
        this.submitloading = true
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/upload/', formData, {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          if (response.data !== true) {
            this.dangerMessages = Array.isArray(response.data)
              ? response.data
              : [
                  'Something went wrong - Please check Data uniqueness in particular'
                ]
            this.okayMessages = false
          } else {
            this.dangerMessages = []
            this.okayMessages = true
          }
        } catch (e) {
          console.error(e)
          this.submitLoading = false
        }
      }
    }
  }
</script>
