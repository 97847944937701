<template>
  <v-navigation-drawer
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
  >
    <v-list dense>
      <template v-for="item in items">
        <v-list-item
          v-if="isPrompted(item)"
          :key="item.text"
          @click="$router.push(item.link)"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['drawerUpdate'],
    data: () => ({
      dialog: false,
      drawer: false, //Put null to set it depending on screen size
      items: [
        {
          icon: 'mdi-chart-bar',
          text: 'Reporting',
          link: '/reporting',
          admin: false,
          superAdmin: false
        },
        {
          icon: 'mdi-chart-bar',
          text: 'Reporting Global',
          link: '/reporting-global',
          admin: true,
          superAdmin: false
        },
        {
          icon: 'mdi-chart-bar',
          text: 'Reporting RevOptimize',
          link: '/reporting-revoptimize',
          admin: true,
          superAdmin: false
        },
        //{ icon: 'mdi-chart-bar', text: 'Reporting', link: '/reporting'},
        {
          icon: 'mdi-chart-bar',
          text: 'Google Analytics',
          link: '/gareport',
          admin: true,
          superAdmin: false
        },
        {
          icon: 'mdi-sitemap',
          text: 'Mapping',
          link: '/mapping',
          admin: true,
          superAdmin: false
        },
        {
          icon: 'mdi-flash',
          text: 'Sites',
          link: '/sites',
          admin: true,
          superAdmin: true
        },
        {
          icon: 'mdi-flash',
          text: 'Customers',
          link: '/customers',
          admin: true,
          superAdmin: true
        },
        {
          icon: 'mdi-account',
          text: 'Users',
          link: '/users',
          admin: true,
          superAdmin: true
        }
      ]
    }),
    watch: {
      drawerUpdate() {
        this.drawer = !this.drawer
      }
    },
    created() {},
    methods: {
      isPrompted(item) {
        if (this.$store.getters.isAdmin == 0)
          return item.admin == false ? true : false
        if (this.$store.getters.isAdmin == 1)
          return item.superAdmin == false ? true : false
        if (this.$store.getters.isAdmin == 2) return true
      }
    }
  }
</script>
