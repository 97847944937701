<template>
  <v-card>
    <v-container fluid style="margin-top: 10px">
      <app-overlay :loading="loading" />
      <v-row>
        <v-col v-for="type in seriesTypes" :key="type" cols="12" md="4">
          <app-chart
            :type="type"
            :label="labels[type]"
            :data="timeSeries[type]"
            :total="catTotals[type]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import moment from 'moment'
  import Chart from './Chart.vue'
  import Overlay from '@/components/common/Overlay.vue'
  export default {
    components: {
      AppChart: Chart,
      AppOverlay: Overlay
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['reportingData', 'loading', 'labels'],
    data() {
      return {
        catTotals: {
          revenue: 0,
          impressions: 0,
          cpm: 0
        },
        seriesTypes: ['revenue', 'impressions', 'cpm']
      }
    },
    computed: {
      timeSeries() {
        let series = {}
        let cpmArray = {}
        const cats = ['revenue', 'impressions']
        cats.forEach((type) => {
          series[type] = []
        })

        series.cpm = [{ name: 'cpm', data: [] }]
        if (this.reportingData !== undefined) {
          this.reportingData.forEach((item) => {
            const unixDate = moment.utc(item.day, 'YYYYMMDD').format('x')
            cats.forEach((type) => {
              //Creates a new object { name: 'appnexus', data : []}
              if (series[type].find((x) => x.name == item.name) == undefined) {
                series[type].push({ name: item.name, data: [] })
              }
              //Adds a [time, value] to { name: 'appnexus', data : []}}
              let foo = series[type].find((x) => x.name == item.name)
              if (type == 'revenue') {
                item[type] = parseFloat(item[type])
              }
              if (type == 'impressions') {
                item[type] = parseInt(item[type])
              }
              foo.data.push([parseInt(unixDate), item[type]])
            })
            if (cpmArray[unixDate] == undefined) {
              cpmArray[unixDate] = { revenue: 0, impressions: 0 }
            } // Creates a new CPM array
            cpmArray[unixDate].revenue += parseFloat(item.revenue)
            cpmArray[unixDate].impressions += parseInt(item.impressions)
          })
        }

        Object.keys(cpmArray).forEach((unixDate) => {
          series.cpm[0].data.push([
            parseInt(unixDate),
            (cpmArray[unixDate].revenue / cpmArray[unixDate].impressions) * 1000
          ])
        })
        return series
      }
    },
    watch: {
      timeSeries() {
        this.setCatTotals()
      }
    },
    created() {},
    methods: {
      setCatTotals() {
        const cats = ['revenue', 'impressions']
        cats.forEach((type) => {
          this.catTotals[type] = 0
          this.timeSeries[type].forEach((item) => {
            item.data.forEach((elem) => {
              this.catTotals[type] += elem[1]
            })
          })
        })
        this.catTotals['cpm'] =
          (this.catTotals['revenue'] / this.catTotals['impressions']) * 1000
      }
    }
  }
</script>
