<template>
  <v-row>
    <v-col cols="12" md="6">
      <app-partner-select
        :refresh="refresh"
        @selectPartner="selectedPartner = $event"
        @refreshPerformed="refresh = false"
      />
    </v-col>
    <v-col cols="12" md="6">
      <app-partner-create @partnerCreated="refresh = true" />
    </v-col>
  </v-row>
</template>
<script>
  import PartnerSelect from './PartnerSelect.vue'
  import PartnerCreate from './PartnerCreate.vue'
  export default {
    components: {
      appPartnerSelect: PartnerSelect,
      appPartnerCreate: PartnerCreate
    },
    data() {
      return {
        selectedPartner: 0,
        refresh: false
      }
    },
    watch: {
      selectedPartner() {
        this.$emit('selectedPartner', this.selectedPartner)
      }
    }
  }
</script>
