<template>
  <v-container fluid>
    <div class="base-title" style="margin-bottom: 10px">
      <h2>Customer Configuration</h2>
    </div>
    <v-card>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" rounded dark style="margin: 10px" v-on="on">
              Create new customer
            </v-btn>
          </template>
          <app-element-input
            :mapping-specs="mappingSpecs"
            @close="dialog = false"
            @save="closeDialog"
          />
        </v-dialog>
      </v-card-actions>
      <v-card-text>
        <app-overlay :loading="loading" />
        <app-customer-select :datas="datas" @updateItems="updateData" />
      </v-card-text>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
          rounded
          color="success"
          style="margin: 10px"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import CustomerSelect from './CustomerSelect'
  import Overlay from '@/components/common/Overlay.vue'
  import ElementInput from '@/components/common/ElementInput'
  export default {
    components: {
      appCustomerSelect: CustomerSelect,
      appOverlay: Overlay,
      appElementInput: ElementInput
    },
    data() {
      return {
        customerSelects: [],
        marginTypeSelects: [],
        datas: [],
        submitLoading: false,
        loading: false,
        dialog: false,
        mappingSpecs: {
          apiEndPoint: 'customers/customers',
          type: {
            category: 'customer'
          }
        }
      }
    },
    created() {
      this.getDataFromApi()
    },
    methods: {
      closeDialog() {
        this.dialog = false
        this.getDataFromApi()
      },
      async getDataFromApi() {
        this.loading = true
        const request = {
          type: 'customers/customers',
          action: 'index'
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.customerSelects = response.data.customerSelect
          this.marginTypeSelects = response.data.marginTypeSelect
          this.datas = response.data.data
          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      },
      async submit() {
        this.submitLoading = true
        const request = {
          type: 'customers/customers',
          action: 'save',
          data: this.datas
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.submitLoading = false
        } catch (e) {
          console.error(e)
          this.submitLoading = false
        }
      },
      updateData(data) {
        this.datas = data
      }
    }
  }
</script>
