<template>
  <v-data-iterator
    :items="datas"
    item-key="id"
    :items-per-page="10"
    :search="search"
  >
    <template v-slot:header>
      <v-text-field v-model="search" clearable label="Search" />
    </template>
    <template v-slot:default="props">
      <v-container>
        <v-row v-for="item in props.items" :key="item.id">
          <v-col xs="6" class="selectColumn">
            <v-text-field
              readonly
              single-line
              :value="item.name"
            ></v-text-field>
          </v-col>
          <v-col xs="6" class="selectColumn">
            <v-autocomplete
              v-model="item.customerId"
              :items="customerSelects"
              clearable
              label="Customer Mapping"
            ></v-autocomplete>
          </v-col>
          <v-col xs="6" class="selectColumn">
            <v-text-field
              v-model="item.gaViewId"
              :label="'Google Analytics View Id'"
            ></v-text-field>
          </v-col>
          <v-col xs="4" class="selectColumn">
            <v-checkbox
              v-model="item.isRevOptimize"
              :label="'RevOptimize'"
            ></v-checkbox>
          </v-col>
          <v-col xs="6" class="selectColumn">
            <app-site-delete :item="item" @deleteItem="deleteSite" />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-data-iterator>
</template>

<script>
  import SiteDelete from './SiteDelete'
  export default {
    components: {
      appSiteDelete: SiteDelete
    },
    props: ['datas', 'customerSelects'],
    data: () => ({
      search: ''
    }),
    methods: {
      deleteSite(id) {
        if (id) {
          let listItems = this.datas.filter(function (item) {
            return item.id != id
          })
          this.$emit('updateItems', listItems)
        }
      }
    }
  }
</script>

<style scoped>
  .selectColumn {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .selectClassification {
    width: 500px;
  }
</style>
