<template>
  <v-card>
    <v-card-title>
      <span class="headline">New {{ mappingSpecs.type.category }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="name" label="Name" required></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">Close</v-btn>
      <v-btn color="blue darken-1" text @click="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import axios from 'axios'
  export default {
    props: ['mappingSpecs'],
    data() {
      return {
        name: ''
      }
    },
    methods: {
      close() {
        this.name = ''
        this.$emit('close')
      },
      async save() {
        const request = {
          type: this.mappingSpecs.apiEndPoint,
          action: 'create',
          data: this.name
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.name = ''
          this.$emit('save')
        } catch (e) {
          console.error(e)
          this.close()
        }
      }
    }
  }
</script>
