<template>
  <v-card>
    <v-container py-0 fluid>
      <v-row>
        <v-col v-for="cat in cats" :key="cat">
          <v-autocomplete
            v-model="filters[cat]"
            :items="selects[cat]"
            :label="labels[cat]"
            multiple
            clearable
            @blur="emitChange"
            @click.clear="emitChange"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import { ucFirst } from './formatters.js'
  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['cats', 'filters', 'source', 'customerId'],
    data() {
      return {
        //cats : ['bidder','custadunit','device','invtype','inventory'],
        loading: false,
        //filters : {},
        selects: {}
      }
    },
    computed: {
      labels() {
        let output = {}
        this.cats.forEach((cat) => {
          output[cat] = cat.charAt(0).toUpperCase() + cat.slice(1)
        })
        return output
      }
    },
    watch: {
      customerId() {
        this.getDataFromApi()
      }
    },
    created() {
      this.emitChange()
      this.getDataFromApi()
    },
    methods: {
      async getDataFromApi() {
        this.loading = true
        const request = {
          type: this.source,
          action: 'getSelects',
          data: { customerId: this.customerId }
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          const output = {}
          Object.keys(response.data).forEach(function (key) {
            const foo = response.data[key].map(function (item) {
              return {
                text: ucFirst(item.text),
                value: item.value
              }
            })
            output[key] = foo
          })
          this.selects = output
          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      },
      emitChange() {
        this.$emit('filtersUpdate', this.filters)
      }
    }
  }
</script>
