<template>
  <v-card>
    <v-card-actions class="d-flex flex-row-reverse">
      <v-btn rounded dark color="info" @click="filterUnknown">
        Filter Unknown
      </v-btn>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" rounded dark style="margin: 10px" v-on="on">
            Create new
          </v-btn>
        </template>
        <app-element-input
          :mapping-specs="mappingSpecs"
          @close="dialog = false"
          @save="closeDialog"
        />
      </v-dialog>
    </v-card-actions>
    <v-card-text>
      <v-container fluid>
        <app-overlay :loading="loading" />
        <app-mapping-select :selects="selects" :datas="datas" />
      </v-container>
    </v-card-text>
    <v-card-actions class="d-flex flex-row-reverse">
      <v-btn
        rounded
        color="success"
        style="margin: 10px"
        :loading="submitLoading"
        :disabled="submitLoading"
        @click="submit"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import MappingSelect from './MappingSelect.vue'
  import Overlay from '@/components/common/Overlay.vue'
  import ElementInput from '@/components/common/ElementInput.vue'
  export default {
    components: {
      AppMappingSelect: MappingSelect,
      AppOverlay: Overlay,
      AppElementInput: ElementInput
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['mappingSpecs', 'selectedPartner', 'selectedCategory'],
    data() {
      return {
        selects: [],
        datas: [],
        submitLoading: false,
        loading: false,
        dialog: false
      }
    },
    computed: {},
    watch: {
      mappingSpecs() {
        this.getDataFromApi()
      }
    },
    created() {
      this.getDataFromApi()
    },
    methods: {
      closeDialog() {
        this.dialog = false
        this.getDataFromApi()
      },
      async getDataFromApi() {
        this.loading = true
        const request = {
          type: this.mappingSpecs.apiEndPoint,
          action: 'index'
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.selects = response.data.select
          this.datas = response.data.data
          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      },
      async submit() {
        this.submitLoading = true
        const request = {
          type: this.mappingSpecs.apiEndPoint,
          action: 'save',
          data: this.datas
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.submitLoading = false
        } catch (e) {
          console.error(e)
          this.submitLoading = false
        }
      },
      filterUnknown() {
        this.datas = this.datas.filter((data) => {
          return data.reportingId == 0
        })
      }
    }
  }
</script>
