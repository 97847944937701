import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: {}
  },
  getters: {
    isAdmin: (state) => {
      return state.user.isAdmin == undefined ? 0 : state.user.isAdmin
    },
    customerDbId: (state) => {
      return state.user.customerId == undefined ? 0 : state.user.customerId
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    }
  }
})

export default store
