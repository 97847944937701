import { render, staticRenderFns } from "./CustomerSelect.vue?vue&type=template&id=152a4493&scoped=true&"
import script from "./CustomerSelect.vue?vue&type=script&lang=js&"
export * from "./CustomerSelect.vue?vue&type=script&lang=js&"
import style0 from "./CustomerSelect.vue?vue&type=style&index=0&id=152a4493&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152a4493",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VContainer,VDataIterator,VRow,VTextField})
