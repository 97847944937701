<template>
  <v-data-iterator
    :items="datas"
    item-key="id"
    :items-per-page="10"
    :search="search"
  >
    <template v-slot:header>
      <v-text-field v-model="search" clearable label="Search" />
    </template>
    <template v-slot:default="props">
      <v-container>
        <v-row v-for="item in props.items" :key="item.id">
          <v-col xs="6" class="selectColumn">
            <v-text-field v-model="item.name" label="Name"></v-text-field>
          </v-col>
          <v-col xs="6" class="selectColumn">
            <v-text-field
              v-model="item.commission"
              label="Commission %"
            ></v-text-field>
          </v-col>
          <v-col xs="6" class="selectColumn">
            <app-customer-delete :item="item" @deleteItem="deleteCustomer" />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-data-iterator>
</template>

<script>
  import CustomerDelete from './CustomerDelete'
  export default {
    components: {
      appCustomerDelete: CustomerDelete
    },
    props: {
      datas: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      search: ''
    }),
    methods: {
      deleteCustomer(id) {
        if (id) {
          let listItems = this.datas.filter(function (item) {
            return item.id != id
          })
          this.$emit('updateItems', listItems)
        }
      }
    }
  }
</script>

<style scoped>
  .selectColumn {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .selectClassification {
    width: 500px;
  }
</style>
