<template>
  <v-container fluid>
    <app-toolbar
      :title="'RPM analysis'"
      :filters="filters"
      :source="'gareport/reporting'"
      @datesUpdate="datesUpdate"
    />
    <app-filters
      :cats="cats"
      :filters="filters"
      :source="'gareport/reporting'"
      @filtersUpdate="filtersUpdate"
    />
    <app-charts :reporting-data="reportingData.perDay" :loading="loading" />
    <app-per-criteria
      :reporting-data="reportingData.perCriteria"
      :loading="loading"
      :cats="cats"
      @perCriteriaUpdate="perCriteriaUpdate"
    />
  </v-container>
</template>

<script>
  import axios from 'axios'
  import Toolbar from '@/components/reporting/Toolbar.vue'
  import Filters from '@/components/common/Filters.vue'
  import Charts from './charts/index.vue'
  import PerCriteria from './perCriterias/index.vue'

  export default {
    components: {
      AppFilters: Filters,
      AppToolbar: Toolbar,
      AppCharts: Charts,
      AppPerCriteria: PerCriteria
    },
    data() {
      return {
        loading: false,
        dates: [],
        filters: {},
        reportingData: {
          perCriteria: {
            device: [],
            site: []
          }
        },
        dataSync: false
      }
    },
    computed: {
      cats() {
        return Object.keys(this.reportingData.perCriteria)
      }
    },
    created() {
      this.cats.forEach((cat) => {
        this.filters[cat] = []
      })
    },
    methods: {
      datesUpdate(dates) {
        this.dates = dates
        if (this.dataSync) this.getDataFromApi()
        this.dataSync = true
      },
      filtersUpdate(foo) {
        this.filters = {}
        this.filters = foo
        if (this.dataSync) this.getDataFromApi()
        this.dataSync = true
      },
      perCriteriaUpdate(foo) {
        this.filters[foo.category].push(foo.id)
        if (this.dataSync) this.getDataFromApi()
        this.dataSync = true
      },
      async getDataFromApi() {
        this.loading = true
        const request = {
          type: 'gareport/reporting',
          action: 'getData',
          data: {
            filters: this.filters,
            dates: this.dates
          }
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.reportingData = response.data
          console.log(this.reportingData)
          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      }
    }
  }
</script>
