import Vue from 'vue'
import Router from 'vue-router'
import Auth from '@okta/okta-vue'

import HomeComponent from '@/pages/Home.vue'

import Index from '@/pages/index.vue'
import Login from '@/pages/login/index.vue'

import Mapping from '@/pages/mapping/index.vue'
import Reporting from '@/pages/reporting/index.vue'
import ReportingGlobal from '@/pages/reportingGlobal/index.vue'
import ReportingRevOptimize from '@/pages/reportingRevOptimize/index.vue'
import GaReport from '@/pages/gareport/index.vue'
import Upload from '@/pages/upload/index.vue'

import Sites from './../pages/site/index.vue'
import Customers from './../pages/customer/index.vue'

import UserList from './../pages/user/userList/index.vue'
import UserCreate from './../pages/user/userCreate/index.vue'
import UserEdit from './../pages/user/userEdit/index.vue'

import config from './../config'

Vue.use(Router)
Vue.use(Auth, config.oidc)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: Login,
      name: 'Login'
    },
    {
      path: '/implicit/callback',
      component: Auth.handleCallback()
    },
    {
      path: '/',
      component: Index,
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          component: Reporting,
          alias: 'reporting',
          name: 'reporting'
        },
        {
          path: 'reporting-global',
          component: ReportingGlobal,
          meta: { admin: true }
        },
        {
          path: 'reporting-revoptimize',
          component: ReportingRevOptimize,
          meta: { admin: true }
        },
        { path: 'mapping', component: Mapping, meta: { admin: true } },
        { path: 'gareport', component: GaReport, meta: { admin: true } },
        { path: 'upload', component: Upload, meta: { admin: true } },
        {
          path: 'sites',
          component: Sites,
          meta: { admin: true, superAdmin: true }
        },
        {
          path: 'customers',
          component: Customers,
          meta: { admin: true, superAdmin: true }
        },
        {
          path: 'users',
          component: UserList,
          meta: { admin: true, superAdmin: true }
        },
        {
          path: 'user/create',
          component: UserCreate,
          meta: { admin: true, superAdmin: true }
        },
        {
          path: 'user/:id',
          component: UserEdit,
          props: true,
          meta: { admin: true, superAdmin: true }
        }
      ]
    },

    /*
    {
      path: '/',
      component: Reporting,
      meta: { requiresAuth: true }
    },
    
    { path: '/reporting', component: Reporting, meta: { requiresAuth: true }},
    { path: '/gareport', component: GaReport,  meta: { requiresAuth: true }},
    { path: '/mapping', component: Mapping, meta: { requiresAuth: true }},
    { path: '/upload', component: Upload, meta: { requiresAuth: true }},
    */
    { path: '*', component: HomeComponent }
  ]
})

const onAuthRequired = async (from, to, next) => {
  if (
    from.matched.some((record) => record.meta.requiresAuth) &&
    !(await Vue.prototype.$auth.isAuthenticated())
  ) {
    console.log('Unidentified')
    // Navigate to custom login page
    next({ name: 'Login' })
  } else {
    next()
  }
}

router.beforeEach(onAuthRequired)

export default router

//router.beforeEach(Vue.prototype.$auth.authRedirectGuard())

/*
router.beforeEach((to, from, next) => {
  Vue.prototype.$auth.isAuthenticated()
  .then((res) => {
    console.log()
    if(res == true){
      //next();
    } else {
      console.log(Vue.prototype.$auth.loginRedirect())
    }
  })
  //next();
});
*/

//export default router
