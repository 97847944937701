<template>
  <v-form>
    <v-card class="pa-10">
      <v-card-title> User {{ user.email }} </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-for="(item, key) in textFields"
              :key="key"
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="user[item.model]"
                :label="item.label"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="user.isAdmin"
                :items="userSelect"
                class="ma-4"
                label="Admin"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-customer-selector
                v-if="user.isAdmin == 0"
                :customer-db-id="user.customerId"
                @customerUpdate="updateCustomerId"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="mr-4" :loading="loading" @click="save">
          Save
        </v-btn>
        <v-btn color="warning" @click="backToList"> Back </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import CustomerSelector from '@/components/customerSelect'

  export default {
    components: {
      appCustomerSelector: CustomerSelector
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['id'],
    data: () => ({
      user: {},
      loading: false,
      textFields: [
        { model: 'firstName', label: 'First Name' },
        { model: 'lastName', label: 'Last Name' }
      ],
      userSelect: [
        { value: 0, text: 'User' },
        { value: 1, text: 'Admin' },
        { value: 2, text: 'Super Admin' }
      ]
    }),
    created() {
      this.getUser()
    },
    methods: {
      async getUser() {
        const request = {
          type: 'users/users',
          action: 'get',
          data: {
            id: this.id
          }
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.user = response.data
        } catch (e) {
          console.error(e)
        }
      },
      async save() {
        const request = {
          type: 'users/users',
          action: 'update',
          data: this.user
        }
        this.loading = true
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.loading = false
          console.log('save: ' + response.data)
        } catch (e) {
          this.loading = false
          console.error(e)
        }
      },
      updateCustomerId(customerId) {
        this.user.customerId = customerId
      },
      async backToList() {
        this.$router.push('/users')
      }
    }
  }
</script>
