<template>
  <v-app id="inspire">
    <app-topbar @drawerUpdate="updateDrawer"></app-topbar>
    <app-sidebar :drawer-update="drawerUpdate"></app-sidebar>
    <v-main>
      <router-view v-if="loading"></router-view>
    </v-main>
  </v-app>
</template>

<script>
  import Sidebar from '@/components/layout/Sidebar.vue'
  import Topbar from '@/components/layout/Navbar.vue'
  import axios from 'axios'

  export default {
    components: {
      appSidebar: Sidebar,
      appTopbar: Topbar
    },
    data: () => ({
      source: '',
      drawerUpdate: 0,
      loading: false
    }),
    created() {
      this.getUser()
    },
    methods: {
      updateDrawer() {
        this.drawerUpdate += 1
      },
      async getUser() {
        console.log('We try to get user')
        let user
        try {
          user = await this.$auth.getUser()
        } catch (e) {
          console.log('We did not get user')
          console.log(e)
        }
        console.log('user', user)
        const request = {
          type: 'user',
          action: 'getUser',
          data: {
            email: user.email
          }
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.$store.commit('setUser', response.data)
          if (
            this.$route.meta.admin == true &&
            this.$store.state.user.isAdmin == 0
          )
            this.$router.push('/')
          if (
            this.$route.meta.superAdmin == true &&
            this.$store.state.user.isAdmin == 1
          )
            this.$router.push('/')
          this.loading = true
        } catch (e) {
          console.error(e)
        }
      },
      async getUserDetails() {}
    }
  }
</script>
