<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <div id="okta-signin-container"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import OktaSignIn from '@okta/okta-signin-widget'
  import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

  import config from './../../config'

  export default {
    name: 'Login',
    mounted: function () {
      this.$nextTick(function () {
        this.widget = new OktaSignIn({
          /**
           * Note: when using the Sign-In Widget for an OIDC flow, it still
           * needs to be configured with the base URL for your Okta Org. Here
           * we derive it from the given issuer for convenience.
           */
          baseUrl: config.oidc.issuer.split('/oauth2')[0],
          clientId: config.oidc.clientId,
          redirectUri: config.oidc.redirectUri,
          logo: require('@/assets/logo2020.png'),
          i18n: {
            fr: {
              'primaryauth.title': 'Se connecter'
            }
          },
          authParams: {
            pkce: true,
            issuer: config.oidc.issuer,
            display: 'page',
            scopes: config.oidc.scopes
          }
        })
        this.widget.renderEl(
          { el: '#okta-signin-container' },
          () => {
            /**
             * In this flow, the success handler will not be called because we redirect
             * to the Okta org for the authentication workflow.
             */
          },
          (err) => {
            throw err
          }
        )
      })
    },
    destroyed() {
      // Remove the widget from the DOM on path change
      this.widget.remove()
    }
  }
</script>
