<template>
  <div>
    <v-toolbar dense flat style="background-color: #fafafa">
      <v-toolbar-title>
        <h4>{{ category | ucFirst }}</h4>
      </v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-card-title primary-title> </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="frontData"
          disable-pagination
          disable-filtering
          dense
          hide-default-footer
          :sort-by="['revenue']"
          :sort-desc="[true]"
          @click:row="filterItem"
        >
          <template v-slot:item.name="{ item }">
            {{ item.name | ucFirst }}
          </template>
          <template v-slot:item.revenue="{ item }">
            {{ item.revenue | revenueFormat }}
          </template>
          <template v-slot:item.impressions="{ item }">
            {{ item.impressions | impressionsFormat }}
          </template>
          <template v-slot:item.cpm="{ item }">
            {{ item.cpm | cpmFormat }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  //| cpmFormat
  import * as formatters from '@/components/common/formatters.js'
  export default {
    filters: {
      revenueFormat(item) {
        return formatters.revenueFormatter(item)
      },
      cpmFormat(item) {
        return formatters.cpmFormatter(item)
      },
      impressionsFormat(item) {
        return formatters.impressionsFormatter(item)
      },
      ucFirst(item) {
        return formatters.ucFirst(item)
      }
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['category', 'reportingData', 'loading'],
    data() {
      return {
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Revenue', value: 'revenue' },
          { text: 'Impressions', value: 'impressions' },
          { text: 'CPM', value: 'cpm' }
        ]
      }
    },
    computed: {
      frontData() {
        const output = []
        this.reportingData.forEach((item) => {
          const foo = {}
          Object.keys(item).forEach((key) => (foo[key] = item[key]))
          foo.cpm = (item.revenue / item.impressions) * 1000
          output.push(foo)
        })
        return output
      }
    },
    methods: {
      filterItem(item) {
        const filter = {
          category: this.category,
          id: parseInt(item.id)
        }
        this.$emit('filter', filter)
      }
    }
  }
</script>
