<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex lg10 offset-lg1>
        <h2 class="text-xs-center">Welcome to Reworld Media Reporting Tool</h2>
      </v-flex>
      <!--
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-btn
            :href="source"
            icon
            large
            target="_blank"
            v-on="on"
          >
            <v-icon large>mdi-code-tags</v-icon>
          </v-btn>
        </template>
        <span>Source</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            large
            href="https://codepen.io/johnjleider/pen/MNYLdL"
            target="_blank"
            v-on="on"
          >
            <v-icon large>mdi-codepen</v-icon>
          </v-btn>
        </template>
        <span>Codepen</span>
      </v-tooltip>
      -->
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        source: '/'
      }
    }
  }
</script>

<!--

<template>
	<h1>Salut {{claims.name }}</h1>
</template>

<script>
export default {
  name: 'home',
  data: function () {
    return {
      claims: '',
    }
  },
  created () { this.setup() },
  methods: {
    async setup () {
      this.claims = await this.$auth.getUser()
      console.log(this.claims)
    },
    async isAuthenticated () {
      this.authenticated = await this.$auth.isAuthenticated()
    },
  }
}
</script>
-->
