const oktaConfig = {
  clientId: '0oazl8gv98olUuoMA4x5',
  issuer: 'https://dev-560377.okta.com/oauth2/default',
  scopes: ['openid', 'profile', 'email'],
  pkce: true
}

const setConfig = function (oktaConfig) {
  console.log(process.env.NODE_ENV)
  let baseURL
  let apiURL
  if (process.env.NODE_ENV == 'development') {
    ;(baseURL = 'https://dev.reworld.cleoma.fr:8080'),
      (apiURL = 'https://dev.api.reworld.cleoma.fr')
  }
  if (process.env.NODE_ENV == 'production') {
    ;(baseURL = 'https://cleoma.reworldmediafactory.com'),
      (apiURL = 'https://cleoma-api.reworldmediafactory.com')
  }
  return {
    oidc: {
      ...oktaConfig,
      redirectUri: baseURL + '/implicit/callback'
    },
    baseURL,
    apiURL
  }
}

export default setConfig(oktaConfig)
