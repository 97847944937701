<template>
  <v-select
    v-model="selectedPartner"
    :items="items"
    label="Partner"
    :disabled="loading"
  />
</template>

<script>
  import axios from 'axios'
  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['refresh'],
    data() {
      return {
        items: [],
        loading: false,
        selectedPartner: 0
      }
    },
    watch: {
      selectedPartner() {
        this.$emit('selectPartner', this.selectedPartner)
      },
      refresh() {
        if (this.refresh == true) {
          this.getDataFromApi()
          this.$emit('refreshPerformed')
        }
      }
    },
    created() {
      this.getDataFromApi()
    },
    methods: {
      async getDataFromApi() {
        this.loading = true
        const request = {
          type: 'upload/partners',
          action: 'getPartners'
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.items = response.data
          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      }
    }
  }
</script>
