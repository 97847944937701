<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" rounded dark style="margin: 10px" v-on="on">
        Create new
      </v-btn>
    </template>
    <app-partner-input @close="closeDialog" @save="closeDialog" />
  </v-dialog>
</template>

<script>
  import PartnerInput from './PartnerInput.vue'
  export default {
    components: {
      appPartnerInput: PartnerInput
    },
    data() {
      return {
        dialog: false
      }
    },
    methods: {
      closeDialog() {
        this.dialog = false
        this.$emit('partnerCreated')
      }
    }
  }
</script>
