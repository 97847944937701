<template>
  <v-data-iterator
    :items="datas"
    item-key="id"
    :items-per-page="10"
    :search="search"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus'
    }"
  >
    <template v-slot:header>
      <v-text-field v-model="search" clearable label="Search" />
    </template>
    <template v-slot:default="props">
      <v-row v-for="item in props.items" :key="item.id">
        <v-col xs="6" class="selectColumn">
          <v-text-field readonly single-line :value="item.name"></v-text-field>
        </v-col>
        <v-col xs="6" class="selectColumn">
          <v-autocomplete
            v-model="item.reportingId"
            :items="selects"
            clearable
            label="Mapping"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['selects', 'datas'],
    data: () => ({
      search: ''
    })
  }
</script>

<style scoped>
  .selectColumn {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .selectClassification {
    width: 500px;
  }
</style>
