<template>
  <v-container py-0 fluid>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="customerId"
          :items="customers"
          label="Customer"
          @blur="emitChange"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  //import { ucFirst } from './../common/formatters.js'
  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['customerDbId'],
    data() {
      return {
        //cats : ['bidder','custadunit','device','invtype','inventory'],
        loading: false,
        customers: [],
        customerId: 0
      }
    },
    computed: {
      labels() {
        let output = {}
        this.cats.forEach((cat) => {
          output[cat] = cat.charAt(0).toUpperCase() + cat.slice(1)
        })
        return output
      }
    },
    watch: {},
    created() {
      if (this.customerDbId !== undefined) this.customerId = this.customerDbId
      this.getDataFromApi()
    },
    methods: {
      async getDataFromApi() {
        this.loading = true
        const request = {
          type: 'customers/customers',
          action: 'index'
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.customers = response.data.data.map((element) => {
            return { text: element.name, value: element.id }
          }) //We use the customer list endpoint
          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      },
      emitChange() {
        this.$emit('customerUpdate', this.customerId)
      }
    }
  }
</script>
