<template>
  <v-container pa-6 fluid>
    <v-alert v-if="alert" type="error">
      {{ alertText }}
    </v-alert>
    <v-row>
      <v-col>
        <v-btn color="info" class="mr-4" @click="createUser"> New </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="users" :loading="loading">
          <template v-slot:item.actions="{ item }">
            <v-btn rounded small color="primary" @click="editUser(item)">
              Update
            </v-btn>
            <app-user-delete
              :item="item"
              @deleteItem="deleteUser"
              @alert="manageAlert"
            />
          </template>
          <template v-slot:item.isAdmin="{ item }">
            {{ checkAdmin(item.isAdmin) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import axios from 'axios'
  import userDelete from './userDelete'
  export default {
    components: {
      appUserDelete: userDelete
    },
    data: () => ({
      loading: false,
      users: [],
      alert: false,
      alertText: '',
      headers: [
        { text: 'Id', align: 'left', value: 'id' },
        { text: 'Email', align: 'left', value: 'email' },
        { text: 'Admin', align: 'left', value: 'isAdmin' },
        { text: 'Actions', align: 'right', value: 'actions' }
      ]
    }),
    created() {
      this.getDataFromApi()
    },
    methods: {
      async getDataFromApi() {
        this.loading = true
        const request = {
          type: 'users/users',
          action: 'index'
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          this.users = response.data
          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      },
      checkAdmin(state) {
        switch (state) {
          case 1:
            return 'Admin'
            break
          case 2:
            return 'Super Admin'
            break
          default:
            return 'User'
        }
      },
      editUser(user) {
        this.$router.push('/user/' + user.id)
      },
      createUser() {
        this.$router.push('/user/create')
      },
      deleteUser(id) {
        if (id) {
          this.users = this.users.filter(function (user) {
            return user.id != id
          })
        }
      },
      manageAlert(alertText) {
        this.alertText = alertText
        this.alert = true
        setTimeout(() => {
          this.alert = false
        }, 3000)
      }
    }
  }
</script>
