<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="category in cats" :key="category" cols="12" md="12">
        <app-cat-datatable
          :reporting-data="reportingData[category]"
          :category="category"
          :loading="loading"
          @filter="filter"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CatDatatable from './CatDatatable.vue'
  export default {
    components: {
      AppCatDatatable: CatDatatable
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['reportingData', 'loading', 'cats'],
    data() {
      return {}
    },
    computed: {
      /*categories(){
				return Object.keys(this.reportingData)
			}*/
    },
    watch: {},
    created() {},
    methods: {
      filter(filter) {
        this.$emit('perCriteriaUpdate', filter)
      }
    }
  }
</script>
