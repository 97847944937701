<template>
  <v-btn
    v-if="item.id"
    color="red"
    rounded
    style="margin: 10px; color: white"
    :loading="loading"
    @click="deleteSite(item.id)"
  >
    Delete
  </v-btn>
</template>

<script>
  import axios from 'axios'
  export default {
    props: ['item'],
    data: () => ({
      loading: false
    }),
    methods: {
      async deleteSite(id) {
        if (id && confirm('Êtes vous sûr de vouloir supprimer ce site ?')) {
          const request = {
            type: 'sites/sites',
            action: 'delete',
            data: id
          }
          this.loading = true
          try {
            const accessToken = await this.$auth.getAccessToken()
            const response = await axios.post('/', JSON.stringify(request), {
              headers: { Authorization: `Bearer ${accessToken}` }
            })
            if (response) {
              this.$emit('deleteItem', id)
              this.loading = false
            }
          } catch (e) {
            console.error(e)
            this.loading = false
          }
        }
      }
    }
  }
</script>
