import moment from 'moment'

const revenueNumberFormat = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0
})

const percentageFormat = new Intl.NumberFormat('fr-FR', {
  style: 'percent',
  maximumFractionDigits: 1,
  minimumFractionDigits: 1
})

const cpmNumberFormat = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
})

export const ucFirst = function (item) {
  return item.charAt(0).toUpperCase() + item.slice(1)
}

export const revenueFormatter = function (item) {
  return revenueNumberFormat.format(item)
}

export const percentageFormatter = function (item) {
  return percentageFormat.format(item)
}

export const impressionsFormatter = function (item) {
  item = parseInt(item)
  let pref = ''
  let num = ''
  if (item >= 1000000000) {
    pref = 'g'
    num = (item / 1000000000).toFixed(1)
  } else if (item >= 1000000) {
    pref = 'm'
    num = (item / 1000000).toFixed(1)
  } else if (item >= 1000) {
    pref = 'k'
    num = (item / 1000).toFixed(1)
  } else {
    num = item
  }
  return num + ' ' + pref
}

export const cpmFormatter = function (item) {
  return cpmNumberFormat.format(item)
}

export const revenueTooltipFormatter = function () {
  let output = moment(this.x).format('MMM Do, YY') + '<br/>'
  output += this.series.name + ': <b>'
  output += revenueFormatter(this.y)
  if (this.total !== undefined) {
    output += '<br/>Total: <b>' + revenueFormatter(this.total) + '</b>'
  }
  return output
}

export const impressionsTooltipFormatter = function () {
  let output = moment(this.x).format('MMM Do, YY') + '<br/>'
  output += this.series.name + ': <b>'
  output += impressionsFormatter(this.y)
  if (this.total !== undefined) {
    output += '<br/>Total: <b>' + impressionsFormatter(this.total) + '</b>'
  }
  return output
}

export const percentageTooltipFormatter = function () {
  let output = moment(this.x).format('MMM Do, YY') + '<br/>'
  output += this.series.name + ': <b>'
  output += percentageFormatter(this.y)
  if (this.total !== undefined) {
    output += '<br/>Total: <b>' + percentageFormatter(this.total) + '</b>'
  }
  return output
}

export const cpmTooltipFormatter = function () {
  let output = moment(this.x).format('MMM Do, YY') + '<br/>'
  output += this.series.name + ': <b>'
  output += cpmFormatter(this.y)
  if (this.total !== undefined) {
    output += '<br/>Total: <b>' + cpmFormatter(this.total) + '</b>'
  }
  return output
}
