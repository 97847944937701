<template>
  <div>
    <v-alert v-if="alert" type="error">
      {{ alertText }}
    </v-alert>
    <v-form>
      <v-card class="pa-10">
        <v-card-title> New user </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-for="(item, key) in textFields" :key="key">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="user[item.model]"
                  :label="item.label"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" class="mr-4" :loading="loading" @click="save">
            Create
          </v-btn>
          <v-btn color="warning" @click="backToList"> Back </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data: () => ({
      user: {},
      loading: false,
      alert: false,
      alertText: '',
      textFields: [
        { model: 'firstName', label: 'First Name' },
        { model: 'lastName', label: 'Last Name' },
        { model: 'email', label: 'Email' }
      ]
    }),
    created() {},
    methods: {
      async save() {
        if (
          this.user.firstName == undefined ||
          this.user.lastName == undefined ||
          this.user.email == undefined
        ) {
          this.manageAlert('All fields must be filled')
          return
        }
        this.loading = true
        const request = {
          type: 'users/users',
          action: 'create',
          data: this.user
        }
        try {
          const accessToken = await this.$auth.getAccessToken()
          const response = await axios.post('/', JSON.stringify(request), {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
          if (response.data.code == 0) {
            this.manageAlert("L'utilisateur existe déjà.")
            this.loading = false
          } else if (response.data.code == 1 && response.data.id) {
            this.$router.push('/user/' + response.data.id)
          } else {
            this.manageAlert("Erreur lors de la création de l'utilisateur!")
            console.error(response)
            this.loading = false
          }
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      },
      manageAlert(alertText) {
        this.alertText = alertText
        this.alert = true
        setTimeout(() => {
          this.alert = false
        }, 3000)
      },
      async backToList() {
        this.$router.push('/userlist')
      }
    }
  }
</script>
