<template>
  <div>
    <highcharts style="height: 200px" :options="chartOptions" />
  </div>
</template>
<script>
  import moment from 'moment'
  import { Chart } from 'highcharts-vue'
  import * as formatters from '@/components/common/formatters.js'

  export default {
    components: {
      highcharts: Chart
    },
    props: {
      type: {
        type: String,
        default: ''
      },
      data: {
        type: Array,
        default: () => []
      },
      total: {
        type: Number,
        default: 0
      },
      parameters: {
        type: Object,
        default: () => ({ type: 'raw', formatter: 'impressions' })
      }
    },
    data() {
      return {}
    },
    computed: {
      chartOptions() {
        //console.log(formatters[this.formatterMapping[this.type] + "Formatter"])
        const output = {}
        output.credits = { enabled: false }
        output.chart = { type: 'area' }
        output.title = {}
        output.title.style = '{color: #666666}'
        output.legend = { enabled: false }
        output.xAxis = {}
        output.xAxis.type = 'datetime'
        //output.xAxis.units = 'week';s
        output.yAxis = {}
        output.yAxis.title = { text: '' }
        output.yAxis.min = 0
        output.tooltip = {}
        output.tooltip.useHTML = true
        output.tooltip.split = false
        output.plotOptions = {}
        //output.plotOptions.column = {};
        //output.plotOptions.column.stacking = "normal";
        //output.plotOptions.column.dataLabels = {enabled : false};
        output.series = this.data
        output.title.text =
          formatters.ucFirst(this.type) +
          ': ' +
          formatters[this.parameters.formatter + 'Formatter'](this.total)

        //We need get through this as the this object can be accessible or not depending on the function structure
        switch (this.parameters.formatter) {
          case 'impressions':
            output.yAxis.labels = {
              formatter: function () {
                return formatters['impressionsFormatter'](this.value)
              }
            }
            output.tooltip = {
              formatter: formatters['impressionsTooltipFormatter']
            }
            break
          case 'revenue':
            output.yAxis.labels = {
              formatter: function () {
                return formatters['revenueFormatter'](this.value)
              }
            }
            output.tooltip = {
              formatter: formatters['revenueTooltipFormatter']
            }
            break
          case 'cpm':
            output.yAxis.labels = {
              formatter: function () {
                return formatters['cpmFormatter'](this.value)
              }
            }
            output.tooltip = {
              formatter: formatters['cpmTooltipFormatter']
            }
            break
          case 'percentage':
            output.yAxis.labels = {
              formatter: function () {
                return formatters['percentageFormatter'](this.value)
              }
            }
            output.tooltip = {
              formatter: formatters['percentageTooltipFormatter']
            }
            break
        }
        //console.log(output)
        return output
      }
      /*
			sum(){
				let sum = 0;
				this.data.forEach((item) =>{
					item.data.forEach((elem) => {
						sum += elem[1]
					})
				})
				return sum
			}
			*/
    }
  }
</script>
