var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticStyle:{"background-color":"#fafafa"},attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_c('h4',[_vm._v(_vm._s(_vm._f("ucFirst")(_vm.category)))])])],1),_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.frontData,"disable-pagination":"","disable-filtering":"","dense":"","hide-default-footer":"","sort-by":['revenue'],"sort-desc":[true]},on:{"click:row":_vm.filterItem},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ucFirst")(item.name))+" ")]}},{key:"item.sessions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("impressionsFormat")(item.sessions))+" ")]}},{key:"item.pageViews",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("impressionsFormat")(item.pageViews))+" ")]}},{key:"item.bounceRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("percentageFormat")(item.bounceRate))+" ")]}},{key:"item.averageSessionDuration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("impressionsFormat")(item.averageSessionDuration))+" ")]}},{key:"item.pageViewPerSessions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("impressionsFormat")(item.pageViewPerSessions))+" ")]}},{key:"item.revenue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("revenueFormat")(item.revenue))+" ")]}},{key:"item.impressions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("impressionsFormat")(item.impressions))+" ")]}},{key:"item.cpm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cpmFormat")(item.cpm))+" ")]}},{key:"item.rpmPerPageView",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cpmFormat")(item.rpmPerPageView))+" ")]}},{key:"item.rpmPerSession",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cpmFormat")(item.rpmPerSession))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }