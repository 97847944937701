<template>
  <div>
    <highcharts :options="chartOptions" />
  </div>
</template>
<script>
  import moment from 'moment'
  import { Chart } from 'highcharts-vue'
  import * as formatters from '@/components/common/formatters.js'

  export default {
    components: {
      highcharts: Chart
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['type', 'label', 'data', 'total'],
    computed: {
      chartOptions() {
        const type = this.type // Revenue, Impressions, CPM
        const total = formatters[type + 'Formatter'](this.total)
        const title = this.label + ' : ' + total
        let output = {}
        output.credits = { enabled: false }
        output.chart = { type: 'column' }
        output.title = {}
        output.title.style = '{color: #666666}'
        output.legend = { enabled: false }
        output.xAxis = {}
        output.xAxis.type = 'datetime'
        //output.xAxis.units = 'week';
        output.yAxis = {}
        output.yAxis.title = { text: '' }
        output.tooltip = {}
        output.tooltip.useHTML = true
        output.tooltip.split = false
        output.plotOptions = {}
        output.plotOptions.column = {}
        output.plotOptions.column.stacking = 'normal'
        output.plotOptions.column.dataLabels = { enabled: false }
        output.series = this.data
        output.title.text = title
        output.yAxis.labels = {
          formatter: function () {
            return formatters[type + 'Formatter'](this.value)
          }
        }
        output.tooltip = {
          formatter: formatters[type + 'TooltipFormatter']
        }
        return output
      }
      /*
      sum(){
        let sum = 0;
        this.data.forEach((item) =>{
          item.data.forEach((elem) => {
            sum += elem[1]
          })
        })
        return sum
      }
      */
    }
  }
</script>
