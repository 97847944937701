<template>
  <div>
    <v-btn
      v-for="timeChoice in timeChoices"
      :key="timeChoice"
      outlined
      small
      style="border: none"
      @click="selectTime(timeChoice)"
    >
      {{ timeChoice }}
    </v-btn>
    <v-menu v-model="datePickerActive" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" outlined v-on="on">
          {{ dates[0] }} -> {{ dates[1] }}
        </v-btn>
      </template>
      <v-card>
        <!--<v-card-title class="headline">Unlimited music now</v-card-title>-->

        <v-card-text>
          <v-date-picker v-model="dates" range></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="datePickerActive = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data() {
      return {
        timeChoices: [
          'D',
          '7D',
          'PW',
          '30D',
          'MTD',
          'PM',
          'QTD',
          'PQ',
          'YTD',
          'PY'
        ],
        dates: [],
        datePickerActive: false
      }
    },
    computed: {},
    watch: {
      dates() {
        const dates = this.dates.map((x) => x.replace(/-/g, ''))
        this.$emit('datesUpdate', dates)
      }
    },
    created() {
      this.selectTime('7D')
    },
    methods: {
      selectTime(timeChoice) {
        let dates = []
        switch (timeChoice) {
          case 'D':
            dates[0] = moment().subtract(1, 'days')
            dates[1] = moment().subtract(1, 'days')
            break
          case '7D':
            dates[0] = moment().subtract(7, 'days')
            dates[1] = moment().subtract(1, 'days')
            break
          case 'PW':
            dates[0] = moment().startOf('week').subtract(6, 'days')
            dates[1] = moment().startOf('week')
            break
          case '30D':
            dates[0] = moment().subtract(30, 'days')
            dates[1] = moment().subtract(1, 'days')
            break
          case 'MTD':
            dates[0] = moment().startOf('month')
            dates[1] = moment().subtract(1, 'days')
            break
          case 'PM':
            dates[0] = moment().subtract(1, 'month').startOf('month')
            dates[1] = moment().subtract(1, 'month').endOf('month')
            break
          case 'QTD':
            dates[0] = moment().startOf('quarter')
            dates[1] = moment().subtract(1, 'days')
            break
          case 'PQ':
            dates[0] = moment().subtract(1, 'quarter').startOf('quarter')
            dates[1] = moment().subtract(1, 'quarter').endOf('quarter')
            break
          case 'YTD':
            dates[0] = moment().startOf('year')
            dates[1] = moment().subtract(1, 'days')
            break
          case 'PY':
            dates[0] = moment().subtract(1, 'year').startOf('year')
            dates[1] = moment().subtract(1, 'year').endOf('year')
            break
        }
        if (dates[0] > dates[1]) {
          dates = [dates[1], dates[0]]
        }
        this.dates = [
          dates[0].format('YYYY-MM-DD'),
          dates[1].format('YYYY-MM-DD')
        ]
      }
    }
  }
</script>
