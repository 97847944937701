import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import config from './config.js'
import store from './store'

axios.defaults.baseURL = config.apiURL
axios.defaults.headers.post['Content-Type'] = 'application/json'

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  render: (h) => h(App)
})
